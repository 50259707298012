import React, { FC, memo } from 'react'
import { spacings } from '@sport1/news-styleguide/spacings'
import { ComponentType } from '@sport1/types/web'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ItemCardProps } from '..'
import { TeaserSizeTypes } from '@/types/teaser'
import { defaultDisplaySizes } from '@/hooks/useBreakpointDisplaySize'

const TwoItemCardContent: FC<ItemCardProps> = ({
    teaser,
    getTeaserComponent,
    displaySizes = defaultDisplaySizes,
    hasRightColumn,
}) => {
    if (teaser.length < 2) return null
    const { isMobile, isTablet, isDesktop } = displaySizes
    const isFirstElementEditorailTeaser = teaser[0].type === ComponentType.EDITORIAL_STREAM_TEASER
    return (
        <div data-testid="two-item-card-content">
            <NonFlexingContainer display={['none', 'none', 'none', 'flex']}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: hasRightColumn ? 'row' : 'column',
                        gap: isFirstElementEditorailTeaser ? undefined : spacings['spacing-6'],
                        marginTop: isFirstElementEditorailTeaser
                            ? spacings['spacing-6']
                            : undefined,
                        justifyContent: 'flex-start',
                    }}
                    data-testid="desktop-teasers-wrapper"
                >
                    {Array.from({ length: 2 }, (_, index) => (
                        <div key={`${teaser[index].id}_desktop_wrapper`}>
                            {getTeaserComponent({
                                index,
                                type: hasRightColumn
                                    ? TeaserSizeTypes.MID
                                    : TeaserSizeTypes.HORIZONTAL_BIG,
                                isCardTeaser: isDesktop,
                                includeAdsForSizes: {
                                    isDesktop: true,
                                    isTablet: false,
                                    isMobile: false,
                                },
                            })}
                        </div>
                    ))}
                </div>
            </NonFlexingContainer>
            <NonFlexingContainer display={['none', 'none', 'flex', 'none']}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: spacings['spacing-6'],
                    }}
                    data-testid="tablet-teasers-wrapper"
                >
                    {Array.from({ length: 2 }, (_, index) => (
                        <div key={`${teaser[index].componentKey}_tablet_wrapper`}>
                            {getTeaserComponent({
                                index,
                                type: TeaserSizeTypes.HORIZONTAL_BIG,
                                isCardTeaser: isTablet,
                                includeAdsForSizes: {
                                    isDesktop: false,
                                    isTablet: true,
                                    isMobile: false,
                                },
                            })}
                        </div>
                    ))}
                </div>
            </NonFlexingContainer>
            <NonFlexingContainer display={['flex', 'flex', 'none', 'none']}>
                <div
                    style={{
                        display: 'flex',
                        gap: spacings['spacing-6'],
                        flexDirection: 'column',
                        maxWidth: '100%',
                        flexGrow: 1,
                    }}
                    data-testid="mobile-teasers-wrapper"
                >
                    <div>
                        {getTeaserComponent({
                            index: 0,
                            type: TeaserSizeTypes.BIG,
                            isCardTeaser: isMobile,
                            includeAdsForSizes: {
                                isDesktop: false,
                                isTablet: false,
                                isMobile: true,
                            },
                        })}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: spacings['spacing-6'],
                        }}
                    >
                        {Array.from({ length: 1 }, (_, index) => (
                            <div key={`${teaser[index].componentKey}_mobile_wrapper`}>
                                {getTeaserComponent({
                                    index: index + 1,
                                    type: TeaserSizeTypes.LIST,
                                    isCardTeaser: isMobile,
                                    includeAdsForSizes: {
                                        isDesktop: false,
                                        isTablet: false,
                                        isMobile: true,
                                    },
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            </NonFlexingContainer>
        </div>
    )
}

export default memo(TwoItemCardContent)
